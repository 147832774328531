





















































import { checkIsMobile } from "@/utils/utils";
import Vue from "vue";
export default Vue.extend({
  computed: {
    isMobile() {
      return checkIsMobile();
    },
  },
});
